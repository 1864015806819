import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LocalizedStrings from 'react-localization';

let translate;
const cache = [];

export const setLanguage = lang => {
  if (!translate) {
    console.warn('Translate Provider Not Initialized');
    return;
  }

  translate.setLanguage(lang);
  localStorage.setItem('localization', lang);
  cache.forEach(m => {
    try {
      m.setState({});
    } catch (e) {}
  });
}

export const TranslateProvider = ({
  children,
  data,
  supportLanguages = ['en','zh_hk', 'zh_cn']
}) => {
  translate = new LocalizedStrings(
    supportLanguages.reduce((prev, option) => {
      prev[option] = Object.entries(data).reduce((prev, [key, value]) => {
        prev[key] = value[option];
        return prev;
      }, {});
      return prev;
    }, {})
  );
  translate.setLanguage(
    localStorage.getItem('localization') || supportLanguages[0]
  );
  return children;
};
export default class extends Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.object
  };
  state = {
    memory: Math.random()
  };
  componentDidMount() {
    cache.push(this);
  }

  componentWillUnmount() {
    const i = cache.findIndex(m => {
      return (
        m.state.memory === this.state.memory && m.props.name === this.props.name
      );
    });
    if (i !== -1) cache.splice(i, 1);
  }

  static setLanguage = setLanguage;

  render() {
    const { name, value = {}, children } = this.props;
    if (typeof children === 'function') return children(translate || {});

    return translate
      ? translate.formatString(translate[name] || name, value)
      : name;
  }
}
