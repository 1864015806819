import React, { Fragment } from 'react';
import Homeimg1 from '../img/home-img1.jpg';
import Question1 from '../img/Question1.png';
import Question2 from '../img/Question2.png';
import Question3 from '../img/Question3.png';
import Question4 from '../img/Question4.png';
import T from './translateRender';

export default () => (
  <div style={{ backgroundColor: '#edf1f2' }}>
    <div className='container py-5'>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <div className='d-flex flex-column h-100'>
          <h1>
            <T name='QuestionTitle' />
          </h1>
          <p className='text-muted pt-3'>
            <T name='QuestionTitlep' />
          </p>
          <div className="d-flex flex-fill align-items-end pb-4 overflow-hidden">
          <img height="100%" style={{objectFit: "cover"}} src={Homeimg1} />
          </div>
          </div>
        </div>
        <div className='col-md-6 col-12'>
          <h1>
            <T name='Question1Title' />
          </h1>
          <div className='d-flex flex-row py-3'>
            <div className='pr-3' >
              <img src={Question1} />
            </div>
            <div>
              <h5>
                <T name='Question1' />
              </h5>
              <p className='text-muted'>
                <T name='Question1p' />
              </p>
            </div>
          </div>
          <div className='d-flex flex-row py-4 '>
            <div className='pr-4' >
              <img src={Question2} />
            </div>
            <div>
              <h5>
                <T name='Question2' />
              </h5>
              <p className='text-muted'>
                <T name='Question2p' />
              </p>
            </div>
          </div>
          <div className='d-flex flex-row py-4'>
            <div className='pr-4'>
              <img src={Question3} />
            </div>
            <div>
              <h5>
                <T name='Question3' />
              </h5>
              <p className='text-muted'>
                <T name='Question3p' />
              </p>
            </div>
          </div>
          <div className='d-flex flex-row py-4'>
            <div className='pr-4'>
              <img src={Question4} />
            </div>
            <div>
              <h5>
                <T name='Question4' />
              </h5>
              <p className='text-muted'>
                <T name='Question4p' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
