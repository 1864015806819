import React, { Component } from 'react';
import Slider from 'react-slick';
import ResponPic1 from '../img/ResponPic1.jpg';
import ResponPic2 from '../img/ResponPic2.jpg';
import ResponPic3 from '../img/ResponPic3.jpg';
import ResponPic4 from '../img/ResponPic4.jpg';
import ResponPic5 from '../img/ResponPic5.jpg';
import ResponPic6 from '../img/ResponPic6.jpg';
import ResponPic7 from '../img/ResponPic7.jpg';
import ResponPic8 from '../img/ResponPic8.jpg';
import ResponPic9 from '../img/ResponPic9.jpg';
import ResponPic10 from '../img/ResponPic10.jpg';
import { Icon } from './Icon';
import T from './translateRender';

class Responsive extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#ffffff', paddingBottom: '6rem' }}>
        <div className='container px-3 py-5 text-center'>
          <h1 className='py-5'>
            <T name="Banner2Header"/>
          </h1>
          <Slider
            dots={true}
            infinite={true}
            speed={500}
            slidesToShow={4}
            slidesToScroll={4}
            initialSlide={0}
            autoplay={true}
            nextArrow={<SampleNextArrow />}
            prevArrow={<SamplePrevArrow />}
            responsive={[
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]}
          >
            <div className="px-2">
              <img className="w-100" src={ResponPic1} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
                <T name="Banner2p1"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic2} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p2"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic3} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p3"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic4} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p4"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic5} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p5"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic6} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p6"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic7} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p7"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic8} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p8"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic9} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p9"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic10} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p10"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic1} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p1"/>
              </p>
            </div>
            <div className="px-2">
              <img className="w-100" src={ResponPic2} />
              <p className='text-white' style={{ backgroundColor: '#000000' }}>
              <T name="Banner2p2"/>
              </p>
            </div>
          </Slider>
        </div>
      </div>
    );
  }
}

export default Responsive;

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return <div onClick={onClick} />;
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return <div onClick={onClick} />;
}
