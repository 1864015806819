import React from 'react';
import BgImg from '../img/BgImg.jpg';
import { Button } from 'reactstrap';
import T from './translateRender';
import { Link } from 'react-router-dom';

export default () => {
  return (
    <div
      className={'text-left p-5 d-flex align-items-center'}
      style={{
        height: '140vh',
        maxHeight: '36rem',
        background: 'url(' + BgImg + ')'
      }}
    >
      <div className='container'>
        <p style={{ color: '#ce1e20' }}>
          <T name='Banner1p' />
        </p>
        <h1 className='text-white font-weight-bolder'>
          <T name='Banner1Header' />
        </h1>
        <div className='mt-5 d-block d-sm-none'>
          <Link className='text-white' to={'/contacts'}>
            <Button className='btn-block rounded-0 px-5 py-3 mr-3' outline color='light'>
              <T name='Banner1Button1' />
            </Button>
          </Link>
          <Link className='text-white' to={'/contacts'}>
            <Button
              className='btn-block rounded-0 px-5 py-3 mt-3 mt-sm-0'
              style={{ backgroundColor: '#ce1e20' }}
            >
              <T name='Banner1Button2' />
            </Button>
          </Link>
        </div>

        <div className='mt-5 d-none d-sm-block'>
          <Link className='text-white' to={'/contacts'}>
            <Button className='rounded-0 px-5 py-3 mr-3' outline color='light'>
              <T name='Banner1Button1' />
            </Button>
          </Link>
          <Link className='text-white' to={'/contacts'}>
            <Button
              className='rounded-0 px-5 py-3 mt-3 mt-sm-0'
              style={{ backgroundColor: '#ce1e20' }}
            >
              <T name='Banner1Button2' />
            </Button>
          </Link>
        </div>

      </div>
    </div>
  );
};
