import React, { Component } from 'react';
import Content from '../../components/Content';
import BgImg from '../../img/BgImg.jpg';
import T from '../../components/translateRender';
import kool11 from '../../img/Equipment-1.jpg';
import p1000 from '../../img/Equipment-2.jpg';
import { Button } from 'reactstrap';
import { Icon } from '../../components/Icon';
import { Link } from 'react-router-dom';

export default class extends Component {
  render() {
    return (
      <div>
        <div
          className={'text-left p-5 d-flex align-items-center'}
          style={{
            height: '140vh',
            maxHeight: '26rem',
            background: 'url(' + BgImg + ')'
          }}
        >
          <div className='container py-3 py-md-5'>
            <h4 style={{ color: '#ce1e20' }}>
              <T name='EquipmentBanner1p' />
            </h4>
            <h1 className='text-white font-weight-bolder mt-5'>
              <T name='EquipmentBannerHeader' />
            </h1>
          </div>
        </div>
        <div className={'container py-3 py-md-5'}>
          <div className={'row'}>
            <div className={'col-12 col-lg-3'}>
              <img
                className={'img-fluid h-100'}
                style={{ objectFit: 'cover' }}
                src={kool11}
              />
            </div>
            <div className={'col-12 col-lg-3 mb-5 mb-sm-0'}>
              <div
                className={'h-100 d-flex flex-column py-5 px-3'}
                style={{ backgroundColor: '#dde7e8' }}
              >
                <h5>POS Payments</h5>
                <p className={'text-muted row pt-3'}>
                  <div className={'col-1'}>
                    <Icon icon={'faAngleRight'} />
                  </div>
                  <div className={'col-10'}>
                    <span>Accept credit card through a POS</span>
                  </div>
                </p>
                <p className={'text-muted row'}>
                  <div className={'col-1'}>
                    <Icon icon={'faAngleRight'} />
                  </div>
                  <div className={'col-10'}>
                    <span>
                      Virtual terminal for mail, phone and online payments
                    </span>
                  </div>
                </p>
                <p className={'text-muted row'}>
                  <div className={'col-1'}>
                    <Icon icon={'faAngleRight'} />
                  </div>
                  <div className={'col-10'}>
                    <span>Cash drawer and printer connectivity</span>
                  </div>
                </p>
                <div className={'flex-fill'} />
                <div className='d-block d-sm-none'>
                  <Link to={'/kool11'}>
                    <Button className={'btn-block rounded-0 py-3 px-5'}>
                      <span>Learn More</span>
                    </Button>
                  </Link>
                </div>
                <div className='d-none d-sm-block'>
                  <Link to={'/kool11'}>
                    <Button className={'rounded-0 py-3 px-5'}>
                      <span>Learn More</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className={'col-12 col-lg-3'}>
              <img
                className={'img-fluid h-100'}
                style={{ objectFit: 'cover' }}
                src={p1000}
              />
            </div>
            <div className={'col-12 col-lg-3'}>
              <div
                className={'h-100 d-flex flex-column py-5 px-3'}
                style={{ backgroundColor: '#dde7e8' }}
              >
                <h5>Payment Terminals</h5>
                <p className={'text-muted row pt-3'}>
                  <div className={'col-1'}>
                    <Icon icon={'faAngleRight'} />
                  </div>
                  <div className={'col-10'}>
                    <span>Countertop & Wireless Terminals</span>
                  </div>
                </p>
                <p className={'text-muted row'}>
                  <div className={'col-1'}>
                    <Icon icon={'faAngleRight'} />
                  </div>
                  <div className={'col-10'}>
                    <span>Accept credit cards, Chip Cards and Apple Pay</span>
                  </div>
                </p>
                <p className={'text-muted row'}>
                  <div className={'col-1'}>
                    <Icon icon={'faAngleRight'} />
                  </div>
                  <div className={'col-10'}>
                    <span>Wireless & Wifi Options</span>
                  </div>
                </p>
                <div className={'flex-fill'} />
                <div className='d-block d-sm-none'>
                  <Link to={'/p1000'}>
                    <Button className={'btn-block rounded-0 py-3 px-5'}>
                      <span>Learn More</span>
                    </Button>
                  </Link>
                </div>
                <div className='d-none d-sm-block'>
                  <Link to={'/p1000'}>
                    <Button className={'rounded-0 py-3 px-5'}>
                      <span>Learn More</span>
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Content />
      </div>
    );
  }
}
