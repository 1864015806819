import React, { Component } from 'react';
import BgImg from '../../img/BgImg.jpg';
import T from '../../components/translateRender';
import Content from '../../components/Content';
import p1000 from '../../img/p1000.png';
import { Button } from 'reactstrap';

export default class extends Component {
  render() {
    return (
      <div>
        <div
          className={'text-left p-5 d-flex align-items-center'}
          style={{
            height: '140vh',
            maxHeight: '26rem',
            background: 'url(' + BgImg + ')'
          }}
        >
          <div className='container'>
            <h4 style={{ color: '#ce1e20' }}>
              <T name='P1000Banner1p' />
            </h4>
            <h1 className='text-white font-weight-bolder mt-5'>
              <T name='P1000BannerHeader' />
            </h1>
          </div>
        </div>
        <div className={'container'} style={{ paddingTop: 100, paddingBottom: 100 }}>
          <div className={'row'}>
            <div className={'col-12 col-xl-4'}>
              <img className={'img-fluid'} src={p1000} />
            </div>
            <div className={'col-12 col-xl-8'}>
                <h3><T name={'P1000IntroductionHeader'} /></h3>
                <p className={'pt-3'}>
                  <T name={'P1000Detail'} />
                </p>
                {/*<Button>Buy Today</Button>*/}
            </div>
          </div>
        </div>
        <Content />
      </div>
    );
  }
}
