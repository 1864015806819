import React from 'react';
import { Link } from 'react-router-dom';
import { CardFooter } from 'reactstrap';
import { Icon } from '../Icon';
import T from '../translateRender';

import './style.css';
const Footer = () => {
  return (
    <div
      className='pt-5 pb-4'
      style={{
        backgroundColor: '#162027'
      }}
    >
      <div className='container'>
        <div className='row mb-3'>
          <div className='col-md-3 col-12'>
            <img className='w-50' src={require('../../img/unopay_logo.png')} />
            <p className='text-white py-4'>
              <T name="Footer1"/>
            </p>
          </div>
          <div className='col-md-3 col-12'>
            {/*<div className='row'>*/}
            {/*  /!*<div className='col-6'>*!/*/}
            {/*  /!*  <h6 className='text-white '>*!/*/}
            {/*  /!*    <Icon  style={{color:'#ce1e20'}} icon='faAngleRight' />*!/*/}
            {/*  /!*    <span className='pl-2'><T name="Footer2p1"/></span>*!/*/}
            {/*  /!*  </h6>*!/*/}
            {/*  /!*  <h6 className='text-white'>*!/*/}
            {/*  /!*    <Icon  style={{color:'#ce1e20'}} icon='faAngleRight' />*!/*/}
            {/*  /!*    <span className='pl-2'><T name="Footer2p2"/></span>*!/*/}
            {/*  /!*  </h6>*!/*/}
            {/*  /!*  <h6 className='text-white'>*!/*/}
            {/*  /!*    <Icon  style={{color:'#ce1e20'}} icon='faAngleRight' />*!/*/}
            {/*  /!*    <span className='pl-2'><T name="Footer2p3"/></span>*!/*/}
            {/*  /!*  </h6>*!/*/}
            {/*  /!*  <h6 className='text-white'>*!/*/}
            {/*  /!*    <Icon  style={{color:'#ce1e20'}} icon='faAngleRight' />*!/*/}
            {/*  /!*    <span className='pl-2'><T name="Footer2p4"/></span>*!/*/}
            {/*  /!*  </h6>*!/*/}
            {/*  /!*</div>*!/*/}
            {/*  /!*<div className='col-6'>*!/*/}
            {/*  /!*  <h6 className='text-white'>*!/*/}
            {/*  /!*    <Icon  style={{color:'#ce1e20'}} icon='faAngleRight' />*!/*/}
            {/*  /!*    <span className='pl-2'><T name="Footer2p5"/></span>*!/*/}
            {/*  /!*  </h6>*!/*/}
            {/*  /!*  <h6 className='text-white'>*!/*/}
            {/*  /!*    <Icon  style={{color:'#ce1e20'}} icon='faAngleRight' />*!/*/}
            {/*  /!*    <span className='pl-2'><T name="Footer2p6"/></span>*!/*/}
            {/*  /!*  </h6>*!/*/}
            {/*  /!*  <h6 className='text-white'>*!/*/}
            {/*  /!*    <Icon  style={{color:'#ce1e20'}} icon='faAngleRight' />*!/*/}
            {/*  /!*    <span className='pl-2'><T name="Footer2p7"/></span>*!/*/}
            {/*  /!*  </h6>*!/*/}
            {/*  /!*</div>*!/*/}
            {/*</div>*/}
          </div>
          <div className='col-md-6 col-12'>
            <div className='py-4'>
              <h6 className='text-white'><T name="Footer3Header"/></h6>
            </div>
            <div>
              <h6 className='text-white'>
                <Icon  style={{color:'#ce1e20'}} icon='faPhoneAlt' />
                <span className='pl-2'><T name="Footer3p1"/> (852) 3565-4483</span>
              </h6>
              <h6 className='text-white'>
                <Icon  style={{color:'#ce1e20'}} icon='faEnvelope' />
                <span className='pl-2'><T name="Footer3p2"/> findus@unopay.asia</span>
              </h6>
            </div>
            {/* <div className='pt-5'>
              <a className='px-1' href='https://www.facebook.com/'>
                <Icon
                  icon='faFacebookF'
                  type='free-brands'
                  className='text-white'
                />
              </a>
              <a className='px-1' href='https://www.twitter.com/'>
                <Icon
                  icon='faTwitter'
                  type='free-brands'
                  className='text-white'
                />
              </a>
              <a className='px-1' href='https://www.youtube.com/'>
                <Icon
                  icon='faYoutube'
                  type='free-brands'
                  className='text-white'
                />
              </a>
              <a className='px-1' href='https://hk.linkedin.com/'>
                <Icon
                  icon='faLinkedinIn'
                  type='free-brands'
                  className='text-white'
                />
              </a>
              <a className='px-1' href='https://www.google.com/'>
                <Icon
                  icon='faGooglePlusG'
                  type='free-brands'
                  className='text-white'
                />
              </a>
            </div> */}
          </div>
        </div>
        <p className='text-muted'>
          <T name='FooterLast'/>
        </p>
      </div>
    </div>
  );
};

export default Footer;
