import React from 'react';
import Contentpg from '../img/content.jpg';
import T from './translateRender';

export default () => {
  return (
    <div
      style={{
        background: 'url(' + Contentpg + ')',
        backgroundPosition: '25% center'
      }}
    >
      <div className='container text-right'>
        <div className="py-4" />
        <div className="py-5" />
        <div className='d-md-block d-none'>
          <h2>
            <T name="Content"/>
            <span style={{color:'#ce1e20'}}> (852) 3565 - 4483</span>
          </h2>
        </div>
        <div className='d-md-none d-block'>
          <h4>
            <T name="Content"/>
            <span className='pt-5'  style={{color:'#ce1e20'}}> (852) 3565 - 4483</span>
          </h4>
        </div>
        <div className="py-5" />
        <div className="py-4" />
      </div>
    </div>

    // <div className="text-right px-5"
    // style={{
    // maxHeight:"25.6rem",
    // background:"url("+Contentpg+")"
    // }}>
    //     <div className="container"
    //     style={{
    //         paddingTop: "10rem",
    //         paddingBottom: "10rem",
    //     }}

    //     >
    //         <div className="row" style={{paddingLeft:'40%'}}>
    //         <h1>{"Call for a Free Quote:"}</h1>
    //         <h1 className="text-primary">{"(123) 456 - 7890"}</h1>
    //         </div>
    //     </div>
    // </div>
  );
};
