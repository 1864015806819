import React, { Component } from 'react';
import BgImg from '../../img/BgImg.jpg';
import T from '../../components/translateRender';
import ContactForm from '../../components/ContactForm';
import GoogleMap from '../../components/GoogleMap';

export default class extends Component {
  render() {
    return (
      <div>
        <div
          className={'text-left p-5 d-flex align-items-center'}
          style={{
            height: '140vh',
            maxHeight: '26rem',
            background: 'url(' + BgImg + ')'
          }}
        >
          <div className='container'>
            <h4 style={{ color: '#ce1e20' }}>
              <T name='ContactBanner1p' />
            </h4>
            <h1 className='text-white font-weight-bolder mt-5'>
              <T name='ContactBannerHeader' />
            </h1>
          </div>
        </div>
        <ContactForm />
        <GoogleMap />
      </div>
    );
  }
}
