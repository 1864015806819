import React from 'react';
import { Formik } from 'formik';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import T from './translateRender';
import ContentContainer from './ContentContainer';
import Row from 'reactstrap/es/Row';

const ContactForm = () => {
  return (
    <ContentContainer id='contact'>
      <div className={'container row'}>
        <div className='col-lg-6 col-12 text-left'>
          <div>
            <h3 className={'mb-4'}><T name={'ContactDetails'}/></h3>
            <p style={{color:'#ce1e20'}}><T name={'AddressHeader'}/></p>
            <p>
              <T name={'AddressDetail'}/>
            </p>
            <p style={{color:'#ce1e20'}}><T name={'PhoneHeader'}/></p> <p> (852) 3565-4483</p>
            <p style={{color:'#ce1e20'}}><T name={'EmailsHeader'}/></p>
            <p>findus@unopay.asia</p>
          </div>
        </div>

        <div className='col-lg-6 col-12 mt-5 mx-auto'>
          <Formik initialValues={{}} onSubmit={(values,actions) =>{
            setTimeout(()=>{actions.setSubmitting(true
            )},500);
            console.log(values);}}>
            {props => {
              const { values = {}, handleChange, handleSubmit,isSubmitting } = props;
              const { name, email, phone, message } = values;
              if (isSubmitting === false) {
                return (
                    <Form onSubmit={handleSubmit}>
                      <FormGroup>
                        <T>
                          {t => (
                              <Input
                                  required
                                  name={'name'}
                                  placeholder={t['PlaceHolderName']}
                                  values={name}
                                  disabled={isSubmitting}
                                  onChange={handleChange}
                              />
                          )}
                        </T>
                      </FormGroup>
                      <FormGroup>
                        <T>
                          {t => (
                              <Input
                                  required
                                  name={'email'}
                                  placeholder={t['PlaceHolderEmail']}
                                  values={email}
                                  disabled={isSubmitting}
                                  onChange={handleChange}
                              />
                          )}
                        </T>
                      </FormGroup>
                      <FormGroup>
                        <T>
                          {t => (
                              <Input
                                  required
                                  name={'message'}
                                  placeholder={t['PlaceHolderMessage']}
                                  values={message}
                                  disabled={isSubmitting}
                                  onChange={handleChange}
                                  type='textarea'
                              />
                          )}
                        </T>
                      </FormGroup>
                      <div className='mt-5 d-none d-sm-block text-left'>
                        <Button
                            type='submit'
                            color='danger'
                            className='rounded-0 px-4'
                            style={{height: 60}}
                            disabled={isSubmitting}
                        >
                          <T name='question_btn'/>
                        </Button>
                      </div>
                      <div className='mt-5 d-block d-sm-none text-left'>
                        <Button
                            type='submit'
                            color='danger'
                            className='btn-block rounded-0 px-4'
                            style={{height: 60}}
                            disabled={isSubmitting}
                        >
                          <T name='question_btn'/>
                        </Button>
                      </div>
                    </Form>
                )
              }
              if (isSubmitting){
                return (
                    <h4>Sumbit Success
                    <br/>  We will call you back as soon as possible.</h4>


                )

              }

            }}
          </Formik>
        </div>
      </div>
    </ContentContainer>
  );
};
export default ContactForm;
