import React from 'react';
import T from '../translateRender';
import ContentContainer from '../ContentContainer';
import { Link } from 'react-router-dom';

const Index = () => {
  const button = () => <div className='align-bottom'></div>;
  const infoArr = [
    {
      header: <T name='plan_info_header' />,
      info: <T name='plan_info_head' />,
      price: 1,
      button: () => button()
    },
    {
      header: <T name='plan_info_header2' />,
      info: <T name='plan_info_head2' />,
      price: 2.3,
      button: () => button()
    },
    {
      header: <T name='plan_info_header3' />,
      info: <T name='plan_info_head3' />,
      price: 1.5,
      button: () => button()
    }
  ];
  return (
    <ContentContainer className='text-center'>
      <h1 className='text-center mb-5 text-dark'>
        <T name='PlanHeader' />
      </h1>

      <div className='row'>
        {infoArr.map(({ header, info, price, button, description }, i) => {
          return (
            <div key={i} className='col-lg-4 col-md-6 text-center mb-3 mb-lg-0'>
              <div className='card h-100 '>
                <div className='card-body'>
                  <div className='py-5 h-100 d-flex flex-column'>
                    <div className='col d-flex flex-column flex-fill'>
                      <h4 style={{ color: '#ED1C24' }}>{header}</h4>
                      <h6 className={'mb-4'} style={{ color: '#161616' }}>
                        {info}
                      </h6>
                    </div>
                    {!!price && (
                      <div className={'mb-3'}>
                        <div className='d-flex flex-row justify-content-center align-items-center'>
                          <div className={'row'}>
                            <h1>{price + '%'}</h1>
                          </div>
                        </div>

                        <small className={'text-muted col-12'}>
                          per successful card charge
                        </small>
                      </div>
                    )}
                    {!price && (
                      <div className='d-flex flex-column justify-content-center h-100 text-muted'>
                        {description}
                      </div>
                    )}
                    <Link to={'/contacts'}>
                      <div className='align-bottom'>
                        <div className='d-sm-block d-none'>
                          <button
                            type='button'
                            className='rounded-0 btn btn-lg btn-danger'
                          >
                            <T name='layout_btn3' />
                          </button>
                        </div>
                        <button
                          type='button'
                          className='btn-block d-sm-none d-block rounded-0 btn btn-lg btn-danger'
                        >
                          <T name='layout_btn3' />
                        </button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </ContentContainer>
  );
};

export default Index;
