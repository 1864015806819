import React from 'react';

const ContentContainer = ({
  children,
  containerClass = '',
  className = '',
  ...props
}) => {
  return (
    <div className={containerClass}>
      <div className={`container py-5 ${className}`} {...props}>
        <div className='py-5 d-none d-sm-block' />
        {children}
        <div className='py-5 d-none d-sm-block' />
      </div>
    </div>
  );
};

export default ContentContainer;
