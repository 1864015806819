import React from 'react';
import Slick from 'react-slick';
import blue1 from '../img/partner-blue1.png';
import blue2 from '../img/partner-blue2.png';
import blue3 from '../img/partner-blue3.png';
import blue4 from '../img/partner-blue4.png';
import { Icon } from './Icon';

const brands = [blue1, blue2, blue3, blue4, blue1, blue2, blue3, blue4];

class BlueCarousel extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#ce1e20' }}>
        <div className='container py-5'>
          <Slider className='d-lg-block d-none' slidesToShow={4} />
          <Slider className='d-sm-block d-lg-none d-none' slidesToShow={2} />
          <Slider className='d-sm-none d-block' slidesToShow={1} />
        </div>
      </div>
    );
  }
}
export default BlueCarousel;

const Slider = ({ className, slidesToShow = 4 }) => (
  <Slick
    className={className}
    dots={true}
    infinite={true}
    speed={500}
    autoplay={true}
    slidesToShow={slidesToShow}
    slidesToScroll={1}
    appendDots={dots => <div></div>}
    nextArrow={<SampleNextArrow />}
    prevArrow={<SamplePrevArrow />}
  >
    {brands.map((brand, i) => {
      return (
        <div key={i}>
          <img src={brand} style={{ margin: 'auto' }} />
        </div>
      );
    })}
  </Slick>
);

const SampleNextArrow = ({ onClick }) => {
  return (
    <div
      className='text-light'
      style={{ position: 'absolute', right: 0, top: '40%' }}
      onClick={onClick}
    >
      <Icon icon='faAngleRight' />
    </div>
  );
};

const SamplePrevArrow = ({ onClick }) => {
  return (
    <div
      className='text-light'
      style={{ position: 'absolute', left: 0, top: '40%' }}
      onClick={onClick}
    >
      <Icon icon='faAngleLeft' />
    </div>
  );
};
