import React, { useState } from 'react';
import home1 from '../img/home-1.png';
import home2 from '../img/home-2.png';
import home3 from '../img/home-3.png';
import home4 from '../img/home-4.png';
import { Icon } from './Icon';
import T from './translateRender';

const photoArr = [
  {
    img: home1,
    header: <T name='Benefit1Header' />,
    content: <T name='Benefit1Content' />
  },
  {
    img: home2,
    header: <T name='Benefit2Header' />,
    content: <T name='Benefit2Content' />
  },
  {
    img: home3,
    header: <T name='Benefit3Header' />,
    content: <T name='Benefit3Content' />
  },
  {
    img: home4,
    header: <T name='Benefit4Header' />,
    content: <T name='Benefit4Content' />
  }
];

export default () => {
  return (
    <div className='container-fluid'>
      <div
        className='row'
        style={{
          backgroundColor: '#ffffff'
        }}
      >
        {photoArr.map((props, i) => {
          return <RectBlock key={i} {...props} />;
        })}
      </div>
    </div>
  );
};

const RectBlock = ({ img, header, content }) => {
  const [isHover, setHover] = useState(false);
  return (
    <div
      className={`col-lg-3 col-sm-6 col-12 text-center d-flex flex-column justify-content-between align-items-center p-5  ${
        isHover ? 'bg-dark' : 'bg-white'
      }`}
      style={{
        transition: 'all 0.5s'
      }}
      // onMouseEnter={() => setHover(false)}
      // onMouseLeave={() => setHover(false)}
    >
      <div>
        <img style={{color:"#ce1e20"}} className='img-fluid' src={img} />
      </div>
      <h4 style={{color:"#ce1e20"}}>{header}</h4>
      <p className='text-muted py-2'>{content}</p>
      <Icon style={{color:"#ce1e20"}} icon='faLongArrowRight' />
    </div>
  );
};
