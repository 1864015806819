import React, { Component } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const MyMapComponent = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={17}
      defaultCenter={{ lat: 22.3620206, lng: 114.1323153 }}
    >
      <Marker position={{ lat: 22.3620206, lng: 114.1323153 }} />
    </GoogleMap>
  ))
);

export default class extends Component {
  render() {
    return (
      <MyMapComponent
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyD-E-2sXsQ4owkhbGpMqUbYTp9VEA2cx0c&v=3.exp&libraries=geometry,drawing,places'
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
  }
}
