import React, { Fragment, useState } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { Icon } from './Icon';
import { Link } from 'react-router-dom';
import T, {setLanguage} from './translateRender';

export default () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  const defaultLang = localStorage.getItem('localization') || 'en';

  const imgArr = [
    {
      image: require('../img/zh-CN.jpg'),
      name: '簡体',
      value: 'zh_cn'
    },
    {
      image: require('../img/en-US.jpg'),
      name: 'Eng',
      value: 'en'
    },
    {
      image: require('../img/zh-HK.jpg'),
      name: '繁體',
      value: 'zh_hk'
    }
  ];

  let defaultLangData =
    imgArr.find(({ value }) => value === defaultLang) || imgArr[0];
  const [selectedLangData, setSelectedLangData] = useState(defaultLangData);
  return (
    <Navbar className={'sticky-top'} color='white' light expand='xl'>
      <div className='container h-100'>
        <NavbarBrand href='/' className='h-100'>
          <img height={50} src={require('../img/unopay_logo.png')} />
        </NavbarBrand>

        <Fragment>
          <UncontrolledDropdown>
            <DropdownToggle
              nav
              caret
              key={selectedLangData.name}
              className={'d-flex align-items-center px-sm-2 px-0 text-dark'}
            >
              <img
                src={selectedLangData.image}
                width={24}
                className='mr-2 d-none d-sm-block'
                alt=''
              />
              <span className='text-dark'>{selectedLangData.name}</span>
            </DropdownToggle>
            <DropdownMenu>
              {imgArr.map(({ image, name, value }, index) => {
                if (name !== selectedLangData.name) {
                  return (
                    <DropdownItem
                      key={index}
                      onClick={() => {
                        setLanguage(value);
                        defaultLangData = imgArr.find(
                          ({ name: name2 }) => name2 === name
                        );
                        setSelectedLangData(defaultLangData);
                      }}
                    >
                      <img src={image} width={24} className='mr-2' alt='' />
                      {name}
                    </DropdownItem>
                  );
                }
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
          <div className='flex-fill' />
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className='ml-auto bg-white' navbar>
              {/* <NavItem className='px-2'>
                <NavLink>
                  <T name='Footer2p1' />
                </NavLink>
              </NavItem> */}

              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className='px-2' nav>
                  <Link className={'text-muted'} to={'/equipment'}>
                    <T name='Footer2p2' />
                  </Link>
                  <Icon icon='faAngleDown' />
                </DropdownToggle>
                <DropdownMenu right>
                  <Link to={'/kool11'}>
                    <DropdownItem className={'text-muted'}>
                      <T name='Navbar1Item1' />
                    </DropdownItem>
                  </Link>
                  <Link to={'/p1000'}>
                    <DropdownItem className={'text-muted'}>
                      <T name='Navbar1Item2' />
                    </DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>

              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className='px-2' nav>
                  <T name='Footer2p3' /> <Icon icon='faAngleDown' />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <T name='Banner2p1' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p2' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p3' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p4' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p5' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p6' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p7' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p8' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p9' />
                  </DropdownItem>
                  <DropdownItem>
                    <T name='Banner2p10' />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}

              {/* <NavItem className='px-2'>
                <NavLink>
                  <T name='Footer2p4' />
                </NavLink>
              </NavItem> */}

              <NavItem className='px-2'>
                <NavLink>
                  <Link className={'text-muted'} to={'/pricing'}>
                    <T name='Footer2p5' />
                  </Link>
                </NavLink>
              </NavItem>

              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className='px-2' nav>
                  <T name='Footer2p6' /> <Icon icon='faAngleDown' />
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <T name='Navbar3Item1' />
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}

              <NavItem className='px-2'>
                <NavLink>
                  <Link className={'text-muted'} to={'/contacts'}>
                    <T name='Footer2p7' />
                  </Link>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Fragment>
      </div>
    </Navbar>
  );
};
