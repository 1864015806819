import React, { useState, Fragment } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Icon } from './Icon';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { setState } from 'expect/build/jestMatchersObject';
import { bgBlack, bgWhite } from 'ansi-colors';
import TestimonialBG from '../img/TestimonialBG.jpg';
import T from './translateRender';

export default () => {
  return (
    <Fragment>
      <div
        style={{
          position: 'fixed',
          zIndex: -1,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          background: `url(${TestimonialBG})`
        }}
      />
      <div
        className='container text-white'
        style={{ paddingTop: '6rem', paddingBottom: '7rem' }}
      >
        <h1><T name="TestimonialsHeader"/></h1>
        <div className='mt-5 row'>
          <h1
            className='col-1 d-sm-block d-none'
            style={{ fontSize: '5rem', lineHeight: '4rem' }}
          >
            🙶
          </h1>
          <h1
            className='col-1 m-0 d-sm-none'
            style={{ fontSize: '4rem', lineHeight: 0 }}
          >
            🙶
          </h1>
          <div className='col-11 pt-md-3 pt-lg-0'>
            <Slider
              dots={true}
              infinite={true}
              speed={500}
              slidesToShow={1}
              slidesToScroll={1}
              appendDots={dots => <div></div>}
              nextArrow={<SampleNextArrow />}
              prevArrow={<SamplePrevArrow />}
            >
              <div>
                <p>
                  <T name="Testimonials1"/>
                </p>
                <h5><T name="Testimonials1p"/></h5>
                <h6><T name="Testimonials1m"/></h6>
              </div>
              <div>
                <p>
                <T name="Testimonials2"/>
                </p>
                <h5><T name="Testimonials2p"/></h5>
                <h6><T name="Testimonials2m"/></h6>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

function SampleNextArrow({ onClick }) {
  const [isHover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      className={`d-flex justify-content-center align-items-center ${
        isHover ? 'text-dark' : 'text-white'
      }`}
      style={{
        position: 'absolute',
        background: isHover ? 'White' : 'Black',
        left: 35,
        bottom: '-32%',
        width: 25,
        height: 25,
        fontSize: 20,
        cursor: 'pointer'
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon icon='faAngleRight' />
    </div>
  );
}

function SamplePrevArrow({ onClick }) {
  const [isHover, setHover] = useState(false);
  return (
    <div
      onClick={onClick}
      className={`d-flex justify-content-center align-items-center ${
        isHover ? 'text-dark' : 'text-white'
      }`}
      style={{
        position: 'absolute',
        background: isHover ? 'White' : 'Black',
        left: 0,
        bottom: '-32%',
        width: 25,
        height: 25,
        fontSize: 20,
        cursor: 'pointer'
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Icon icon='faAngleLeft' />
    </div>
  );
}
